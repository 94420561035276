<template>
    <div class="gift-detail-body">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/shoppingCenter' }">礼物商城</el-breadcrumb-item>
            <el-breadcrumb-item>礼物详情</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="detail-container">
            <div class="detail-top">
                <div class="top-left">
                    <el-image class="image" fit="fill" :src="bigCover"></el-image>
                </div>
                <div class="top-right">
                    <div class="right-name">{{goods.name}}</div>
                    <div class="right-price">{{goods.ecurrency}}E币</div>
                    <div class="right-number">
                        <el-row>
                             <el-col :span="5">
                            <span class="number-tip">兑换数量</span>
                            </el-col>
                            <el-col :span="5">
                                <el-input style="height:63px" class="my-input" v-model="buyNum" :min="1" :max="10" onkeyup="this.value=this.value.replace(/\D/g,'')" onafterpaste="this.value=this.value.replace(/\D/g,'')"></el-input>
                            </el-col>
                            <el-col :span="1">
                                <div class="n-block"></div>
                            </el-col>
                            <el-col :span="13">
                                <el-button type="primary" class="my-btn" @click="checkOrder">立即兑换</el-button>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="right-img">
                        <div class="img-block">
                            <img v-for="(item,k) in goods.cover" 
                            @click="changeBigCover(item)"
                            class="img" 
                            :src="item" 
                            :key="k"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="detail-bottom">
                <span class="bottom-title">商品详情</span>
                <div class="my-divider"></div>
                <div class="bottom-detail">
                    <span v-html="goods.describe"></span>
                </div>
            </div>
        </div>
        <el-dialog
            :visible.sync="dialogVisible"
            width="400px"
            center
            :before-close="handleClose">
            <div class="my-dialog-content">
                <p>对不起，您的E币不足</p>
                <p>请至少充值<span>{{dialogData}}</span>个E币</p>
            </div>
            <span slot="footer">
                <el-button @click="dialogVisible = false">取消</el-button>
                <!-- <el-button type="primary" @click="dialogVisible = false">去充值</el-button> -->
            </span>
        </el-dialog>
        <el-dialog :visible.sync="resultVisible" width="380px">
            <el-result v-if="orderResult == true" icon="success" title="提交成功" subTitle="管理员会尽快联系您发货">
                <template slot="extra">
                    <el-button type="primary" size="medium" @click="resultVisible = false">确定</el-button>
                </template>
            </el-result>
             <el-result v-else icon="warning" title="提交失败" :subTitle="orderResultMsg">
                <template slot="extra">
                    <el-button type="primary" size="medium" @click="resultVisible = false">返回</el-button>
                </template>
                </el-result>
        </el-dialog>
        <AddressEdit :dialogVisible.sync="addressDialogVisible" :address="editAddress" @close="editAddressClose"></AddressEdit>
        <AddressSelect v-if="addressSelectDialogVisible" :dialogVisible.sync="addressSelectDialogVisible" @chooseAddress="chooseAddress"></AddressSelect>
    </div>
</template>
<script>
import Vue from 'vue'
import { getGoodsDetail,checkOrder,orderGoods,getAddressList } from "@/api/user"
import AddressEdit from '@/components/shopping/AddressEdit' 
import AddressSelect from '@/components/shopping/AddressSelect'
export default {
    name: 'GiftDetail',
    components: {
        AddressEdit,
        AddressSelect
    },
    data() {
        return {
            userId:Vue.ls.get('Login_Userid'),
            id: this.$route.query.id,
            gift: {},
            buyNum:1,
            dialogVisible:false,
            orderResult:true,
            resultVisible:false,
            dialogData:0,
            bigCover:null,
            goods:{
                name:null,
                id:null,
                cover:null,
                ecurrency:null,
                describe:null
            },
            address:{},
            addressDialogVisible: false,
            editAddress: {},
            addressSelectDialogVisible: false
        }
    },
    created(){
        if(!this.id || !this.userId) {
            this.$router.push(`/login?redirect_uri=${location.href}`)
        }else{
            this.initData();
        }
    },
    methods:{
        initData(){
            if(!this.id) return ;
            getGoodsDetail({id:this.id}).then(res => {
                if(res.success){
                    let data = res.result;
                    if(data.cover && data.cover != ''){
                        let coverList = data.cover.split(',');
                        data.cover = coverList;
                        this.bigCover = data.cover[0];
                    }
                    this.goods = res.result;
                }else{
                    this.$message.error(res.message);
                }
            })
        },
        changeBigCover(img){
            this.bigCover = img;
        },
        checkOrder(){
            let postData = {
                studentId:this.userId,
                price:this.goods.ecurrency*1*this.buyNum
            };
            checkOrder(postData).then( res => {
                if(res.success){
                    getAddressList().then( res2 => {
                        if(res2.success){
                            let address = res2.result;
                            if(address && address.length > 0){
                                this.addressSelectDialogVisible = true;
                            }else{
                                this.addressDialogVisible = true;
                            }
                        }else{
                            this.$message.error(res2.message);
                        }
                    })
                }else{
                    this.dialogData = res.message;
                    this.dialogVisible = true;
                }
            })
        },
        editAddressClose(){
            this.addressSelectDialogVisible = false;
            this.addressDialogVisible = true;
        },
        orderGoods(){
            let postData = {
                studentId:this.userId,
                name:this.address.name,
                phone:this.address.phone,
                address:`${this.address.city} ${this.address.area} ${this.address.detailedAddress}`,
                quantity:this.buyNum,
                commodityName:this.goods.name,
                handle:false,
                commodityId:this.goods.id,
                price:this.goods.ecurrency*1*this.buyNum,
                itemPricing:this.goods.ecurrency
            };
            orderGoods(postData).then( res => {
                this.resultVisible = true;
                if(res.success){
                    this.orderResult = true;
                }else{
                    this.orderResult = false;
                    this.orderResultMsg = res.message;
                }
            })
        },
        chooseAddress(address){
            this.address = address;
            this.orderGoods();
        },
        handleClose(){
        }
    }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/css/views/gift-detail.scss';
</style>